// src/components/calculators/NetWorthCalculator.js
import React, { useState } from "react";
import {
  Form,
  Button,
  Grid,
  Segment,
  Statistic,
  Card,
  Message,
  Dropdown,
  List,
  Icon,
} from "semantic-ui-react";
import { Pie } from "react-chartjs-2";
import styles from "./NetWorthCalculator.module.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const initialInputs = {
  assets: [],
  liabilities: [],
};

const assetOptions = [
  { key: "stocks", text: "Stocks", value: "Stocks", description: " (e.g., Apple, Tesla)" },
  { key: "stock_etf", text: "Stock ETF", value: "Stock ETF", description: " (e.g., S&P 500 ETF)" },
  { key: "bond_etf", text: "Bond ETF", value: "Bond ETF", description: " (e.g., Vanguard Total Bond Market ETF)" },
  
  { key: "government_bonds", text: "Government Bonds", value: "Government Bonds", description: " (e.g., US Treasury Bonds)" },
  { key: "corporate_bonds", text: "Corporate Bonds", value: "Corporate Bonds", description: " (e.g., Apple Corporate Bonds)" },
  
  { key: "precious_metals", text: "Precious Metals", value: "Precious Metals", description: " (e.g., Gold, Silver)" },
  
  { key: "crypto", text: "Cryptocurrency", value: "Cryptocurrency", description: " (e.g., Bitcoin, Ethereum)" },
  
  { key: "reits", text: "REITs", value: "REITs", description: " (e.g., Vanguard Real Estate ETF)" },
  { key: "rental_property", text: "Rental Property", value: "Rental Property", description: " (e.g., Investment property)" },
  { key: "primary_residence", text: "Primary Residence", value: "Primary Residence", description: " (e.g., Your home)" },
  
  { key: "cash_equivalents", text: "Cash and Equivalents", value: "Cash and Equivalents", description: " (e.g., Money Market Funds)" },
  
  { key: "retirement_account", text: "Retirement Account", value: "Retirement Account", description: " (e.g., 401(k), IRA)" },
  
  { key: "other", text: "Other", value: "Other", description: " (e.g., Art, Collectibles)" },
];

const liabilityOptions = [
  { key: "mortgage", text: "Mortgage", value: "Mortgage", description: " (e.g., Home loan)" },
  { key: "student_loan", text: "Student Loan", value: "Student Loan", description: " (e.g., Federal student loans)" },
  { key: "personal_loan", text: "Personal Loan", value: "Personal Loan", description: " (e.g., Unsecured personal loan from a bank)" },
  { key: "car_loan", text: "Car Loan", value: "Car Loan", description: " (e.g., Loan for purchasing a vehicle)" },
  { key: "credit_card", text: "Credit Card Debt", value: "Credit Card Debt", description: " (e.g., Outstanding balance on Visa or Mastercard)" },
  { key: "medical", text: "Medical Debt", value: "Medical Debt", description: " (e.g., Unpaid hospital bills)" },
  { key: "payday_loan", text: "Payday Loan", value: "Payday Loan", description: " (e.g., Short-term high-interest loan)" },
  { key: "business_loan", text: "Business Loan", value: "Business Loan", description: " (e.g., Small business loan)" },
  { key: "other", text: "Other", value: "Other", description: " (e.g., Legal liabilities or tax debt)" },
];

const generateColor = (baseColor, index, total) => {
  const factor = index / total;
  const color = baseColor.map((c) =>
    Math.floor(c * (1 - factor) + 255 * factor)
  );
  return `rgb(${color.join(",")})`;
};

const assetBaseColor = [54, 162, 235]; // Blue
const liabilityBaseColor = [255, 99, 132]; // Red

const NetWorthCalculator = () => {
  const [inputs, setInputs] = useState(initialInputs);
  const [newAsset, setNewAsset] = useState({ type: "", value: 0 });
  const [newLiability, setNewLiability] = useState({ type: "", value: 0 });
  const [chartData, setChartData] = useState({});

  const handleAssetChange = (e, { name, value }) => {
    setNewAsset({ ...newAsset, [name]: value });
  };

  const handleLiabilityChange = (e, { name, value }) => {
    setNewLiability({ ...newLiability, [name]: value });
  };

  const addAsset = () => {
    setInputs({ ...inputs, assets: [...inputs.assets, newAsset] });
    setNewAsset({ type: "", value: 0 });
  };

  const addLiability = () => {
    setInputs({
      ...inputs,
      liabilities: [...inputs.liabilities, newLiability],
    });
    setNewLiability({ type: "", value: 0 });
  };

  const removeAsset = (index) => {
    const updatedAssets = inputs.assets.filter((_, i) => i !== index);
    setInputs({ ...inputs, assets: updatedAssets });
  };

  const removeLiability = (index) => {
    const updatedLiabilities = inputs.liabilities.filter((_, i) => i !== index);
    setInputs({ ...inputs, liabilities: updatedLiabilities });
  };

  const formatNumber = (num) => {
    if (num >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(3)}M`;
    }
    return num.toFixed(0);
  };

  const calculateNetWorth = () => {
    const assetSum = inputs.assets.reduce(
      (sum, asset) => sum + parseFloat(asset.value),
      0
    );
    const liabilitySum = inputs.liabilities.reduce(
      (sum, liability) => sum + parseFloat(liability.value),
      0
    );
    return assetSum - liabilitySum;
  };

  const calculateAssetSum = () => {
    const assetSum = inputs.assets.reduce(
      (sum, asset) => sum + parseFloat(asset.value),
      0
    );
    return assetSum;
  };

  const calculateLiabilitySum = () => {
    const liabilitySum = inputs.liabilities.reduce(
      (sum, liability) => sum + parseFloat(liability.value),
      0
    );
    return liabilitySum;
  };

  const generateChartData = () => {
    const assetData = inputs.assets.map((asset) => parseFloat(asset.value));
    const liabilityData = inputs.liabilities.map((liability) =>
      parseFloat(liability.value)
    );

    const assetColors = inputs.assets.map((_, index) =>
      generateColor(assetBaseColor, index, inputs.assets.length)
    );
    const liabilityColors = inputs.liabilities.map((_, index) =>
      generateColor(liabilityBaseColor, index, inputs.liabilities.length)
    );

    return {
      labels: [
        ...inputs.assets.map((asset) => asset.type),
        ...inputs.liabilities.map((liability) => liability.type),
      ],
      datasets: [
        {
          data: [...assetData, ...liabilityData],
          backgroundColor: [...assetColors, ...liabilityColors],
          hoverBackgroundColor: [
            ...assetColors.map((color) =>
              color.replace("rgb", "rgba").replace(")", ", 0.7)")
            ),
            ...liabilityColors.map((color) =>
              color.replace("rgb", "rgba").replace(")", ", 0.7)")
            ),
          ],
        },
      ],
    };
  };

  const handleSubmit = () => {
    const data = generateChartData();
    setChartData(data);
  };

  return (
    <div>
      <div className={styles.formGroup}>
        <h2>Net Worth Calculator</h2>
        <Form>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Segment>
                  <h3>Assets</h3>
                  <Form.Field>
                    <label>Type</label>
                    <Dropdown
                      placeholder="Select Asset"
                      fluid
                      selection
                      options={assetOptions}
                      name="type"
                      value={newAsset.type}
                      onChange={handleAssetChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Value</label>
                    <input
                      type="number"
                      name="value"
                      value={newAsset.value}
                      onChange={(e) =>
                        setNewAsset({ ...newAsset, value: e.target.value })
                      }
                    />
                  </Form.Field>
                  <Button primary onClick={addAsset}>
                    Add Asset
                  </Button>

                  <List divided>
                    {inputs.assets.map((asset, index) => (
                      <List.Item key={index}>
                        <List.Content floated="right">
                          <Button icon onClick={() => removeAsset(index)}>
                            <Icon name="delete" />
                          </Button>
                        </List.Content>
                        <List.Content>
                          {asset.type}: ${asset.value}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <h3>Liabilities</h3>
                  <Form.Field>
                    <label>Type</label>
                    <Dropdown
                      placeholder="Select Liability"
                      fluid
                      selection
                      options={liabilityOptions}
                      name="type"
                      value={newLiability.type}
                      onChange={handleLiabilityChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Value</label>
                    <input
                      type="number"
                      name="value"
                      value={newLiability.value}
                      onChange={(e) =>
                        setNewLiability({
                          ...newLiability,
                          value: e.target.value,
                        })
                      }
                    />
                  </Form.Field>
                  <Button primary onClick={addLiability}>
                    Add Liability
                  </Button>

                  <List divided>
                    {inputs.liabilities.map((liability, index) => (
                      <List.Item key={index}>
                        <List.Content floated="right">
                          <Button icon onClick={() => removeLiability(index)}>
                            <Icon name="delete" />
                          </Button>
                        </List.Content>
                        <List.Content>
                          {liability.type}: ${liability.value}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Button primary onClick={handleSubmit}>
                  Calculate Net Worth
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
      {chartData.labels && (
        <Segment>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <div className={styles.chartContainer}>
                  <Pie data={chartData} />
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Card.Group>
                  <Card>
                    <Card.Content>
                      <Statistic>
                        <Statistic.Label>Net Worth</Statistic.Label>
                        <Statistic.Value>
                          ${formatNumber(calculateNetWorth())}
                        </Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Statistic>
                        <Statistic.Label>Total Assets</Statistic.Label>
                        <Statistic.Value>
                          ${formatNumber(calculateAssetSum())}
                        </Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Statistic>
                        <Statistic.Label>Total Liabilities</Statistic.Label>
                        <Statistic.Value>
                          ${formatNumber(calculateLiabilitySum())}
                        </Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </div>
  );
};

export default NetWorthCalculator;
