import React, { useState } from "react";
import { Form, Button, Grid, Segment, Statistic, Card, Message } from "semantic-ui-react";
import { Line } from "react-chartjs-2";
import styles from "./RetirementCalculator.module.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const initialInputs = {
  age: "30",
  annualIncome: "80000",
  annualExpenses: "30000",
  currentNetWorth: "20000",
  assetAllocationStocks: "80",
  assetAllocationBonds: "5",
  assetAllocationCash: "15",
  expectedReturnStocks: "5",
  expectedReturnBonds: "2",
  expectedReturnCash: "-3",
  inflationRate: "2",
  taxRate: "20",
  withdrawalRate: "4", // Allow users to specify the withdrawal rate
};

const RetirementCalculator = () => {
  const [inputs, setInputs] = useState(initialInputs);
  const [chartData, setChartData] = useState({});
  const [allocationError, setAllocationError] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const calculateOverallReturnRate = () => {
    const {
      assetAllocationStocks,
      expectedReturnStocks,
      assetAllocationBonds,
      expectedReturnBonds,
      assetAllocationCash,
      expectedReturnCash,
    } = inputs;

    return (
      (assetAllocationStocks * expectedReturnStocks +
        assetAllocationBonds * expectedReturnBonds +
        assetAllocationCash * expectedReturnCash) /
      100
    );
  };

  const calculateTimeToRetirement = () => {
    const { annualIncome, annualExpenses, currentNetWorth, inflationRate } = inputs;
    const annualSavings = parseFloat(annualIncome) - parseFloat(annualExpenses);
    const target = parseFloat(annualExpenses) * 25;
    let years = 0;
    let accumulated = parseFloat(currentNetWorth);

    if (annualSavings <= 0) {
      return Infinity; // If annual savings are not positive, retirement is not possible
    }

    while (accumulated < target) {
      accumulated += annualSavings;
      accumulated += (accumulated * calculateOverallReturnRate()) / 100;
      accumulated /= 1 + parseFloat(inflationRate) / 100; // Adjust for inflation
      years++;
      // To prevent infinite loops or very long loops in extreme cases
      if (years > 100) {
        return Infinity; // Assume retirement is not possible within a reasonable timeframe
      }
    }

    return years;
  };

  const generateChartData = () => {
    const yearsToRetirement = calculateTimeToRetirement();
    const age = parseInt(inputs.age);
    const yearsToDeath = 80 - age;
    let accumulated = parseFloat(inputs.currentNetWorth);
    const year = new Date().getFullYear();

    const labels = Array.from({ length: yearsToDeath + 1 }, (_, i) =>
      (year + i).toString()
    );

    const portfolioData = [];
    const savingsData = [];
    const fourPercentData = [];

    let savings = parseFloat(inputs.currentNetWorth);
    const withdrawalRate = parseFloat(inputs.withdrawalRate) / 100;

    for (let i = 0; i <= yearsToDeath; i++) {
      const annualInvestment = parseFloat(inputs.annualIncome) - parseFloat(inputs.annualExpenses);
      if (i < yearsToRetirement) {
        savings += annualInvestment;
        accumulated += annualInvestment;
        fourPercentData.push(0);
      } else {
        const withdrawal = accumulated * withdrawalRate;
        const afterTaxWithdrawal = withdrawal * (1 - parseFloat(inputs.taxRate) / 100);
        accumulated -= withdrawal;
        fourPercentData.push(afterTaxWithdrawal);
      }
      accumulated += (accumulated * calculateOverallReturnRate()) / 100; // No tax adjustment here
      accumulated /= 1 + parseFloat(inputs.inflationRate) / 100; // Adjust for inflation
      savings /= 1 + parseFloat(inputs.inflationRate) / 100; // Adjust for inflation
      portfolioData.push(accumulated);
      savingsData.push(savings);
    }

    return {
      labels,
      datasets: [
        {
          label: `${inputs.withdrawalRate}% Rule Withdrawal`,
          data: fourPercentData,
          borderColor: "rgb(75, 192, 192)", // Teal
          backgroundColor: "rgba(75, 192, 192, 0.5)", // Teal
          fill: true,
        },
        {
          label: "Target",
          data: Array(yearsToDeath + 1).fill(parseFloat(inputs.annualExpenses) * 25),
          borderColor: "rgb(255, 99, 132)",
          borderDash: [5, 5],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Savings",
          data: savingsData,
          borderColor: "rgb(255, 255, 0)", // Yellow
          backgroundColor: "rgba(255, 255, 0, 0.5)", // Yellow
          fill: true,
        },
        {
          label: "Portfolio Value",
          data: portfolioData,
          borderColor: "rgb(255, 140, 0)", // Orange
          backgroundColor: "rgba(255, 140, 0, 0.5)", // Orange
          fill: true,
        },
      ],
    };
  };

  const formatNumber = (num) => {
    if (num >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(3)}M`;
    }
    return num.toFixed(0);
  };

  const handleSubmit = () => {
    const totalAllocation =
      parseFloat(inputs.assetAllocationStocks) +
      parseFloat(inputs.assetAllocationBonds) +
      parseFloat(inputs.assetAllocationCash);

    if (totalAllocation > 100) {
      setAllocationError("The total asset allocation cannot exceed 100%");
    } else {
      setAllocationError("");
      const data = generateChartData();
      setChartData(data);
    }
  };

  return (
    <div>
      <div className={styles.formGroup}>
        <h2>Retirement Calculator</h2>
        <Form>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Age</label>
                  <input
                    type="number"
                    name="age"
                    value={inputs.age}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Annual Income</label>
                  <input
                    type="number"
                    name="annualIncome"
                    value={inputs.annualIncome}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Annual Expenses</label>
                  <input
                    type="number"
                    name="annualExpenses"
                    value={inputs.annualExpenses}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Current Net Worth (Mony you have right now)</label>
                  <input
                    type="number"
                    name="currentNetWorth"
                    value={inputs.currentNetWorth}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Asset Allocation Stocks (%)</label>
                  <input
                    type="number"
                    name="assetAllocationStocks"
                    value={inputs.assetAllocationStocks}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Asset Allocation Bonds (%)</label>
                  <input
                    type="number"
                    name="assetAllocationBonds"
                    value={inputs.assetAllocationBonds}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Asset Allocation Cash (%)</label>
                  <input
                    type="number"
                    name="assetAllocationCash"
                    value={inputs.assetAllocationCash}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Expected Return Stocks (%)</label>
                  <input
                    type="number"
                    name="expectedReturnStocks"
                    value={inputs.expectedReturnStocks}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Expected Return Bonds (%)</label>
                  <input
                    type="number"
                    name="expectedReturnBonds"
                    value={inputs.expectedReturnBonds}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Expected Return Cash (%)</label>
                  <input
                    type="number"
                    name="expectedReturnCash"
                    value={inputs.expectedReturnCash}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Inflation Rate (%)</label>
                  <input
                    type="number"
                    name="inflationRate"
                    value={inputs.inflationRate}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Tax Rate (%)</label>
                  <input
                    type="number"
                    name="taxRate"
                    value={inputs.taxRate}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Withdrawal Rate (%)</label>
                  <input
                    type="number"
                    name="withdrawalRate"
                    value={inputs.withdrawalRate}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Button primary onClick={handleSubmit}>
                  Calculate
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {allocationError && <Message negative>{allocationError}</Message>}
      </div>
      {chartData.labels && (
        <Segment>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <div className={styles.chartContainer}>
                  <Line data={chartData} />
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Card.Group>
                  <Card>
                    <Card.Content>
                      <Statistic size='small'>
                        <Statistic.Label>Annual Savings</Statistic.Label>
                        <Statistic.Value>${(inputs.annualIncome - inputs.annualExpenses).toFixed(0)}</Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Statistic size='small'>
                        <Statistic.Label>Average Return Rate</Statistic.Label>
                        <Statistic.Value>{calculateOverallReturnRate().toFixed(2)}%</Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Statistic size='small'>
                        <Statistic.Label>Year of Retirement</Statistic.Label>
                        <Statistic.Value>{parseInt(inputs.age) + calculateTimeToRetirement()}</Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Statistic size='small'>
                        <Statistic.Label>Years to Retirement</Statistic.Label>
                        <Statistic.Value>{calculateTimeToRetirement()}</Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                  <Card>
                    <Card.Content>
                      <Statistic size='small'>
                        <Statistic.Label>Retirement Fund Needed</Statistic.Label>
                        <Statistic.Value>${(formatNumber(inputs.annualExpenses * 25))}</Statistic.Value>
                      </Statistic>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </div>
  );
};

export default RetirementCalculator;
