import 'semantic-ui-css/semantic.min.css';
import React, { useState } from 'react';
import { Container, Menu } from 'semantic-ui-react';
import RetirementCalculator from './components/calculators/RetirementCalculator';
import NetWorthCalculator from './components/calculators/NetWorthCalculator';
import CompoundInterestCalculator from './components/calculators/CompoundInterestCalculator'
import PortfolioAllocationCalculator from './components/calculators/PortfolioAllocationCalculator';
// Import other calculators similarly

const App = () => {
  const [activeItem, setActiveItem] = useState('retirement');

  const handleItemClick = (e, { name }) => setActiveItem(name);

  return (
    <div>
      <Menu inverted>
        <Menu.Item
          name='retirement'
          active={activeItem === 'retirement'}
          onClick={handleItemClick}
        >
          Retirement Calculator
        </Menu.Item>
        <Menu.Item
          name='networth'
          active={activeItem === 'networth'}
          onClick={handleItemClick}
        >
          Net Worth Calcualtor
        </Menu.Item>
        <Menu.Item
          name='compoundInterestCalcualtor'
          active={activeItem === 'compoundInterestCalcualtor'}
          onClick={handleItemClick}
        >
          Compound Interest Calculator
        </Menu.Item>
        <Menu.Item
          name='portfolioAllocation'
          active={activeItem === 'portfolioAllocation'}
          onClick={handleItemClick}
        >
          Portfolio Allocation Calculator
          </Menu.Item>

        {/* Add more menu items as needed */}
      </Menu>

      <Container>
        {activeItem === 'retirement' && <RetirementCalculator />}
        {activeItem === 'networth' && <NetWorthCalculator />}
        {activeItem === 'compoundInterestCalcualtor' && <CompoundInterestCalculator />}
        {activeItem === 'portfolioAllocation' && <PortfolioAllocationCalculator />}
        {/* Render other calculators conditionally based on activeItem */}
      </Container>
    </div>
  );
};

export default App;
