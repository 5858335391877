// rules.js
export const portfolioRules = {
    stocks: {
      ideal: (age) => 100 - age,  // Rule based on age
      tolerance: 5,  // Tolerance percentage (+/- 5%)
      warningRange: 10,  // Warn if stock deviation is between 5% to 10%
    },
    bonds: {
      minPercentage: 10,  // Bonds should be at least 10%
      maxPercentage: 20,  // Recommended bond allocation (adjustable)
    },
    gold: {
      maxPercentage: 10,  // Gold should be under 10%
    },
    crypto: {
      maxPercentage: 5,  // Cryptocurrency should be limited to 5% due to volatility
    },
    realEstate: {
      idealPercentage: 10,  // Real Estate should be ideally around 10%
    },
    diversification: {
      minAssetTypes: 3,  // Minimum number of asset types for good diversification
    }
  };