import React, { useState } from "react";
import { Form, Button, Segment, Statistic } from "semantic-ui-react";
import { Line } from "react-chartjs-2";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const initialInputs = {
    initialDeposit: "10000",
    estimatedRateOfReturn: "8",
    monthlyContribution: "100",
    investmentTime: "10",
    commissionFeePercent: "0", // Commission/fee as a percentage of monthly contribution
    annualFeePercent: "0", // Annual fee as a percentage of current balance
    annualInflationRate: "0" // Annual inflation rate
};

const CompoundInterestCalculator = () => {
    const [inputs, setInputs] = useState(initialInputs);
    const [chartData, setChartData] = useState({});
    const [finalValues, setFinalValues] = useState({ portfolioValue: null, totalInvestment: 0, portfolioValueWithoutFee: null });

    const calculateCompoundInterest = () => {
        const P = parseFloat(inputs.initialDeposit);
        const r = parseFloat(inputs.estimatedRateOfReturn) / 100 / 12;
        const PMT = parseFloat(inputs.monthlyContribution);
        const n = parseInt(inputs.investmentTime) * 12;
        const feePercent = parseFloat(inputs.commissionFeePercent) / 100; // Commission/fee as a percentage of monthly contribution
        const annualFeePercent = parseFloat(inputs.annualFeePercent) / 100; // Annual fee as a percentage of current balance
        const monthlyInflationRate = Math.pow(1 + parseFloat(inputs.annualInflationRate) / 100, 1 / 12) - 1; // Monthly inflation rate

        let balanceWithFee = P;
        let balanceWithoutFee = P;
        let dataWithFee = [];
        let dataWithoutFee = [];
        let investmentData = [];
        for (let i = 0; i < n; i++) {
            const fee = PMT * feePercent;
            balanceWithFee = balanceWithFee * (1 + r) + PMT - fee;
            balanceWithoutFee = balanceWithoutFee * (1 + r) + PMT;

            // Inflation
            balanceWithFee = balanceWithFee - (balanceWithFee * monthlyInflationRate);
            balanceWithoutFee = balanceWithoutFee - (balanceWithoutFee * monthlyInflationRate);

            // Apply annual fee once per
            if ((i + 1) % 12 === 0) {
                balanceWithFee -= balanceWithFee * annualFeePercent;

            }

            dataWithFee.push(balanceWithFee);
            dataWithoutFee.push(balanceWithoutFee);
            investmentData.push(P + PMT * (i + 1) - fee * (i + 1));
        }

        const totalInvestmentWithFee = P + PMT * n - PMT * feePercent * n;

        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth(); // 0-11
        const labels = Array.from({ length: n }, (_, i) => {
            const month = (currentMonth + i) % 12;
            const year = currentYear + Math.floor((currentMonth + i) / 12);
            return `${month + 1}/${year}`; // Format: MM/YYYY
        });

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: "Portfolio Value Over Time (With Fees)",
                    data: dataWithFee,
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(75,192,192,1)"
                },
                {
                    label: "Portfolio Value Over Time (Without Fees)",
                    data: dataWithoutFee,
                    fill: false,
                    borderColor: "rgba(255,99,132,1)",
                },
                {
                    label: "Total Investment Over Time (With Fees)",
                    data: investmentData,
                    fill: false,
                    borderColor: "rgba(153,102,255,1)",
                    borderDash: [5, 5],
                }
            ]
        });

        setFinalValues({
            portfolioValue: balanceWithFee.toFixed(2),
            totalInvestment: totalInvestmentWithFee.toFixed(2),
            portfolioValueWithoutFee: balanceWithoutFee.toFixed(2),
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        calculateCompoundInterest();
    };

    return (
        <div>
            <h2>Compound Interest Calculator</h2>
            <Form onSubmit={handleSubmit}>
                <Segment>
                    <Form.Field>
                        <label>Initial Deposit</label>
                        <input
                            type="number"
                            name="initialDeposit"
                            value={inputs.initialDeposit}
                            onChange={(e) => setInputs({ ...inputs, initialDeposit: e.target.value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Monthly Contribution</label>
                        <input
                            type="number"
                            name="monthlyContribution"
                            value={inputs.monthlyContribution}
                            onChange={(e) => setInputs({ ...inputs, monthlyContribution: e.target.value })}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Estimated Rate of Return (%)</label>
                        <input
                            type="number"
                            name="estimatedRateOfReturn"
                            value={inputs.estimatedRateOfReturn}
                            onChange={(e) => setInputs({ ...inputs, estimatedRateOfReturn: e.target.value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Investment Time (Years)</label>
                        <input
                            type="number"
                            name="investmentTime"
                            value={inputs.investmentTime}
                            onChange={(e) => setInputs({ ...inputs, investmentTime: e.target.value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Commission/Fee per Transaction (%)</label>
                        <input
                            type="number"
                            name="commissionFeePercent"
                            value={inputs.commissionFeePercent}
                            onChange={(e) => setInputs({ ...inputs, commissionFeePercent: e.target.value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Annual Fee (%)</label>
                        <input
                            type="number"
                            name="annualFeePercent"
                            value={inputs.annualFeePercent}
                            onChange={(e) => setInputs({ ...inputs, annualFeePercent: e.target.value })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Annual Inflation Rate (%)</label>
                        <input
                            type="number"
                            name="annualInflationRate"
                            value={inputs.annualInflationRate}
                            onChange={(e) => setInputs({ ...inputs, annualInflationRate: e.target.value })}
                        />
                    </Form.Field>
                    <Button type="submit" primary>
                        Calculate
                    </Button>
                </Segment>
            </Form>
            {chartData.labels && (
                <Segment>
                    <Line data={chartData} />
                </Segment>
            )}
            {finalValues.portfolioValue && (
                <Segment>
                    <Statistic.Group>
                        <Statistic>
                            <Statistic.Value>${finalValues.portfolioValue}</Statistic.Value>
                            <Statistic.Label>Final Portfolio Value (With Fees)</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>${finalValues.totalInvestment}</Statistic.Value>
                            <Statistic.Label>Total Investment Amount (With Fees)</Statistic.Label>
                        </Statistic>
                        <Statistic>
                            <Statistic.Value>${finalValues.portfolioValueWithoutFee}</Statistic.Value>
                            <Statistic.Label>Final Portfolio Value (Without Fees)</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                </Segment>
            )}
        </div>
    );
};

export default CompoundInterestCalculator;
