import React, { useState } from "react";
import {
  Form,
  Button,
  Grid,
  Segment,
  Statistic,
  Card,
  Message,
  Dropdown,
  List,
  Icon,
} from "semantic-ui-react";
import { Pie } from "react-chartjs-2";
import styles from "./PortfolioAllocationCalculator.module.css";
import { portfolioRules } from './rules';  // Assuming the rules are in a separate file

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const assetOptions = [
  { key: "stocks", text: "Stocks", value: "Stocks", description: " (e.g., Apple, Tesla)" },
  { key: "stock_etf", text: "Stock ETF", value: "Stock ETF", description: " (e.g., S&P 500 ETF)" },
  { key: "bond_etf", text: "Bond ETF", value: "Bond ETF", description: " (e.g., Vanguard Total Bond Market ETF)" },
  
  { key: "government_bonds", text: "Government Bonds", value: "Government Bonds", description: " (e.g., US Treasury Bonds)" },
  { key: "corporate_bonds", text: "Corporate Bonds", value: "Corporate Bonds", description: " (e.g., Apple Corporate Bonds)" },
  
  { key: "precious_metals", text: "Precious Metals", value: "Precious Metals", description: " (e.g., Gold, Silver)" },
  
  { key: "crypto", text: "Cryptocurrency", value: "Cryptocurrency", description: " (e.g., Bitcoin, Ethereum)" },
  
  { key: "reits", text: "REITs", value: "REITs", description: " (e.g., Vanguard Real Estate ETF)" },
  { key: "rental_property", text: "Rental Property", value: "Rental Property", description: " (e.g., Investment property)" },
  { key: "primary_residence", text: "Primary Residence", value: "Primary Residence", description: " (e.g., Your home)" },
  
  { key: "cash_equivalents", text: "Cash and Equivalents", value: "Cash and Equivalents", description: " (e.g., Money Market Funds)" },
  
  { key: "retirement_account", text: "Retirement Account", value: "Retirement Account", description: " (e.g., 401(k), IRA)" },
  
  { key: "other", text: "Other", value: "Other", description: " (e.g., Art, Collectibles)" },
];

// List of countries
// List of countries
const countryOptions = [
  // North America
  { key: "us", text: "United States", value: "United States" },
  { key: "ca", text: "Canada", value: "Canada" },

  // Europe (all European countries)
  { key: "uk", text: "United Kingdom", value: "United Kingdom" },
  { key: "de", text: "Germany", value: "Germany" },
  { key: "fr", text: "France", value: "France" },
  { key: "it", text: "Italy", value: "Italy" },
  { key: "es", text: "Spain", value: "Spain" },
  { key: "nl", text: "Netherlands", value: "Netherlands" },
  { key: "se", text: "Sweden", value: "Sweden" },
  { key: "no", text: "Norway", value: "Norway" },
  { key: "dk", text: "Denmark", value: "Denmark" },
  { key: "fi", text: "Finland", value: "Finland" },
  { key: "ch", text: "Switzerland", value: "Switzerland" },
  { key: "be", text: "Belgium", value: "Belgium" },
  { key: "ie", text: "Ireland", value: "Ireland" },
  { key: "pt", text: "Portugal", value: "Portugal" },
  { key: "at", text: "Austria", value: "Austria" },
  { key: "gr", text: "Greece", value: "Greece" },
  { key: "is", text: "Iceland", value: "Iceland" },
  { key: "pl", text: "Poland", value: "Poland" },
  { key: "cz", text: "Czech Republic", value: "Czech Republic" },
  { key: "hu", text: "Hungary", value: "Hungary" },
  { key: "ro", text: "Romania", value: "Romania" },
  { key: "bg", text: "Bulgaria", value: "Bulgaria" },
  { key: "sk", text: "Slovakia", value: "Slovakia" },
  { key: "si", text: "Slovenia", value: "Slovenia" },
  { key: "hr", text: "Croatia", value: "Croatia" },
  { key: "ba", text: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { key: "rs", text: "Serbia", value: "Serbia" },
  { key: "mk", text: "North Macedonia", value: "North Macedonia" },
  { key: "al", text: "Albania", value: "Albania" },
  { key: "me", text: "Montenegro", value: "Montenegro" },
  { key: "lt", text: "Lithuania", value: "Lithuania" },
  { key: "lv", text: "Latvia", value: "Latvia" },
  { key: "ee", text: "Estonia", value: "Estonia" },
  { key: "mt", text: "Malta", value: "Malta" },
  { key: "cy", text: "Cyprus", value: "Cyprus" },
  { key: "lu", text: "Luxembourg", value: "Luxembourg" },

  // Oceania
  { key: "au", text: "Australia", value: "Australia" },
  { key: "nz", text: "New Zealand", value: "New Zealand" },

  // Latin America (Western-oriented countries)
  { key: "cl", text: "Chile", value: "Chile" },
  { key: "ar", text: "Argentina", value: "Argentina" },
  { key: "uy", text: "Uruguay", value: "Uruguay" },

  // Add more countries as needed
];


// Generate color function
const generateColor = (baseColor, index, total) => {
  const factor = index / total;
  const color = baseColor.map((c) =>
    Math.floor(c * (1 - factor) + 255 * factor)
  );
  return `rgb(${color.join(",")})`;
};

// Refactored Form to handle age, country (with typing & filtering), and annual net income
const UserInfoForm = ({ age, country, income, onAgeChange, onCountryChange, onIncomeChange }) => (
  <Segment>
    <h3>User Information</h3>
    <Form.Field>
      <label>Your Age</label>
      <input
        type="number"
        name="age"
        value={age}
        onChange={onAgeChange}
      />
    </Form.Field>
    <Form.Field>
      <label>Country</label>
      <Dropdown
        placeholder="Select your country"
        fluid
        selection
        search  // Enables typing and filtering
        clearable  // Allows the user to clear the selection
        options={countryOptions}
        value={country}
        onChange={onCountryChange}
      />
    </Form.Field>
    <Form.Field>
      <label>Annual Net Income</label>
      <input
        type="number"
        name="income"
        value={income}
        placeholder="Enter your annual income"
        onChange={onIncomeChange}
      />
    </Form.Field>
  </Segment>
);

// Refactored Form to handle assets
const AssetForm = ({
  assetOptions,
  newAsset,
  onAssetChange,
  addAsset,
  assets,
  removeAsset,
}) => (
  <Segment>
    <h3>Assets</h3>
    <Form.Field>
      <label>Type</label>
      <Dropdown
        placeholder="Select Asset"
        fluid
        selection
        options={assetOptions}
        name="type"
        value={newAsset.type}
        onChange={onAssetChange}
      />
    </Form.Field>
    <Form.Field>
      <label>Value</label>
      <input
        type="number"
        name="value"
        value={newAsset.value}
        onChange={(e) =>
          onAssetChange(null, { name: "value", value: e.target.value })
        }
      />
    </Form.Field>
    <Button primary onClick={addAsset}>
      Add Asset
    </Button>

    <List divided>
      {assets.map((asset, index) => (
        <List.Item key={index}>
          <List.Content floated="right">
            <Button icon onClick={() => removeAsset(index)}>
              <Icon name="delete" />
            </Button>
          </List.Content>
          <List.Content>
            {asset.type}: ${asset.value}
          </List.Content>
        </List.Item>
      ))}
    </List>
  </Segment>
);

// Refactored to display warnings and suggestions in 3 groups (Green, Yellow, Red) with bullet points
const Messages = ({
  criticalMessages,
  warningMessages,
  suggestionMessages,
}) => (
  <>
    {criticalMessages.length > 0 && (
      <Message color="red">
        <Message.Header>Critical Issues</Message.Header>
        <Message.List>
          {criticalMessages.map((msg, index) => (
            <Message.Item key={index}>{msg}</Message.Item>
          ))}
        </Message.List>
      </Message>
    )}
    {warningMessages.length > 0 && (
      <Message color="yellow">
        <Message.Header>Warnings</Message.Header>
        <Message.List>
          {warningMessages.map((msg, index) => (
            <Message.Item key={index}>{msg}</Message.Item>
          ))}
        </Message.List>
      </Message>
    )}
    {suggestionMessages.length > 0 && (
      <Message color="green">
        <Message.Header>Suggestions</Message.Header>
        <Message.List>
          {suggestionMessages.map((msg, index) => (
            <Message.Item key={index}>{msg}</Message.Item>
          ))}
        </Message.List>
      </Message>
    )}
  </>
);

// Refactored summary component to display Total Assets
const PortfolioSummary = ({ totalAssets }) => (
  <Card.Group>
    <Card>
      <Card.Content>
        <Statistic>
          <Statistic.Label>Total Assets</Statistic.Label>
          <Statistic.Value>${totalAssets}</Statistic.Value>
        </Statistic>
      </Card.Content>
    </Card>
  </Card.Group>
);

// Refactored Chart component
const PortfolioChart = ({ chartData }) => (
  <div className={styles.chartContainer}>
    <Pie data={chartData} />
  </div>
);

const PortfolioAllocationCalculator = () => {
  // Define state variables, including age, country, and income
  const [inputs, setInputs] = useState({ assets: [] });
  const [age, setAge] = useState(0);
  const [country, setCountry] = useState(""); // Initialize country
  const [income, setIncome] = useState(80000); // Initialize income
  const [newAsset, setNewAsset] = useState({ type: "", value: 0 });
  const [chartData, setChartData] = useState({});
  const [criticalMessages, setCriticalMessages] = useState([]);
  const [warningMessages, setWarningMessages] = useState([]);
  const [suggestionMessages, setSuggestionMessages] = useState([]);

  const handleAssetChange = (e, { name, value }) => {
    setNewAsset({ ...newAsset, [name]: value });
  };

  const addAsset = () => {
    setInputs({ ...inputs, assets: [...inputs.assets, newAsset] });
    setNewAsset({ type: "", value: 0 });
  };

  const removeAsset = (index) => {
    const updatedAssets = inputs.assets.filter((_, i) => i !== index);
    setInputs({ ...inputs, assets: updatedAssets });
  };

  const formatNumber = (num) =>
    num >= 1_000_000 ? `${(num / 1_000_000).toFixed(3)}M` : num.toFixed(0);

  const calculateTotalAssets = () => {
    return inputs.assets.reduce(
      (sum, asset) => sum + parseFloat(asset.value),
      0
    );
  };

  const calculateStockPercentage = () => {
    const stock = inputs.assets.find((asset) => asset.type === "Stocks");
    const totalAssets = calculateTotalAssets();
    return stock ? (stock.value / totalAssets) * 100 : 0;
  };

  const generateChartData = () => {
    const assetData = inputs.assets.map((asset) => parseFloat(asset.value));
    const assetColors = inputs.assets.map((_, index) =>
      generateColor([54, 162, 235], index, inputs.assets.length)
    );

    return {
      labels: inputs.assets.map((asset) => asset.type),
      datasets: [
        {
          data: assetData,
          backgroundColor: assetColors,
          hoverBackgroundColor: assetColors.map((color) =>
            color.replace("rgb", "rgba").replace(")", ", 0.7)")
          ),
        },
      ],
    };
  };

  const applyAgeBasedRule = () => {
    const { stocks, bonds, gold, crypto, realEstate, diversification } = portfolioRules;
    const recommendedStockPercentage = stocks.ideal(age);
    const currentStockPercentage = calculateStockPercentage();
    const totalAssets = calculateTotalAssets();
  
    // Clear previous messages
    setCriticalMessages([]);
    setWarningMessages([]);
    setSuggestionMessages([]);
  
    const newCriticalMessages = [];
    const newWarningMessages = [];
    const newSuggestionMessages = [];
  
    // 1. Stock allocation check based on age
    const stockDifference = currentStockPercentage - recommendedStockPercentage;
    if (Math.abs(stockDifference) <= stocks.tolerance) {
      newSuggestionMessages.push(`Your stock allocation is ${currentStockPercentage.toFixed(2)}%, which is ideal.`);
    } else if (Math.abs(stockDifference) > stocks.tolerance && Math.abs(stockDifference) <= stocks.warningRange) {
      newWarningMessages.push(
        `Your stock allocation is ${currentStockPercentage.toFixed(2)}%. It should be closer to ${recommendedStockPercentage}%. Consider adjusting your portfolio.`
      );
    } else if (stockDifference > stocks.warningRange) {
      newCriticalMessages.push(
        `Your stock allocation is ${currentStockPercentage.toFixed(2)}%. It is more than ${stocks.warningRange}% above the recommended ${recommendedStockPercentage}%. Consider reducing your stock holdings.`
      );
    } else if (stockDifference < -stocks.warningRange) {
      const amountToAdd = ((recommendedStockPercentage - currentStockPercentage) / 100) * totalAssets;
      newCriticalMessages.push(
        `Your stock allocation is ${currentStockPercentage.toFixed(2)}%. You are more than ${stocks.warningRange}% under the recommended ${recommendedStockPercentage}%. You may consider adding $${amountToAdd.toFixed(2)} in stocks.`
      );
    }
  
    // 2. Bond allocation check (good: 10-20%, warning: less than 10%, critical: 0%)
    const bondsAsset = inputs.assets.find((asset) => asset.type === "Bonds");
    const bondsPercentage = bondsAsset ? (bondsAsset.value / totalAssets) * 100 : 0;
  
    if (bondsPercentage >= bonds.minPercentage && bondsPercentage <= bonds.maxPercentage) {
      newSuggestionMessages.push(`Your bond allocation is ${bondsPercentage.toFixed(2)}%, which is good.`);
    } else if (bondsPercentage > 0 && bondsPercentage < bonds.minPercentage) {
      newWarningMessages.push(`Your bond allocation is ${bondsPercentage.toFixed(2)}%. Consider increasing it to at least ${bonds.minPercentage}%.`);
    } else if (bondsPercentage === 0) {
      newCriticalMessages.push(`You have no bond allocation. It is recommended to have at least ${bonds.minPercentage}% in bonds for stability.`);
    }
  
    // 3. Gold allocation check (good: <10%, critical: >10%)
    const goldAsset = inputs.assets.find((asset) => asset.type === "Gold");
    const goldPercentage = goldAsset ? (goldAsset.value / totalAssets) * 100 : 0;
  
    if (goldPercentage < gold.maxPercentage) {
      newSuggestionMessages.push(`Your gold allocation is ${goldPercentage.toFixed(2)}%, which is within the ideal range.`);
    } else if (goldPercentage >= gold.maxPercentage) {
      newCriticalMessages.push(`Your gold allocation is ${goldPercentage.toFixed(2)}%. It is too high and should be reduced below ${gold.maxPercentage}%.`);
    }
  
    // 4. Crypto allocation check (good: <5%, critical: >5%)
    const cryptoAsset = inputs.assets.find((asset) => asset.type === "Cryptocurrency");
    const cryptoPercentage = cryptoAsset ? (cryptoAsset.value / totalAssets) * 100 : 0;
  
    if (cryptoPercentage <= crypto.maxPercentage) {
      newSuggestionMessages.push(`Your cryptocurrency allocation is ${cryptoPercentage.toFixed(2)}%, which is acceptable.`);
    } else if (cryptoPercentage > crypto.maxPercentage) {
      newCriticalMessages.push(`Your cryptocurrency allocation is ${cryptoPercentage.toFixed(2)}%. It is too high due to volatility. Consider reducing it to below ${crypto.maxPercentage}%.`);
    }
  
    // 5. Real estate allocation check (good: ~10%)
    const realEstateAsset = inputs.assets.find((asset) => asset.type === "Real Estate");
    const realEstatePercentage = realEstateAsset ? (realEstateAsset.value / totalAssets) * 100 : 0;
  
    if (realEstatePercentage === realEstate.idealPercentage) {
      newSuggestionMessages.push(`Your real estate allocation is ${realEstatePercentage.toFixed(2)}%, which is ideal.`);
    } else if (realEstatePercentage < realEstate.idealPercentage) {
      newWarningMessages.push(`Your real estate allocation is ${realEstatePercentage.toFixed(2)}%. Consider increasing it to around ${realEstate.idealPercentage}%.`);
    } else if (realEstatePercentage > realEstate.idealPercentage) {
      newCriticalMessages.push(`Your real estate allocation is ${realEstatePercentage.toFixed(2)}%. It's higher than the ideal ${realEstate.idealPercentage}%. Consider rebalancing.`);
    }
  
    // 6. Diversification check
    const assetTypes = [...new Set(inputs.assets.map((asset) => asset.type))].length;
  
    if (assetTypes >= diversification.minAssetTypes) {
      newSuggestionMessages.push("Your portfolio is well diversified with multiple asset types.");
    } else if (assetTypes === 2) {
      newWarningMessages.push("Your portfolio could benefit from more diversification. Consider adding more asset types.");
    } else if (assetTypes <= 1) {
      newCriticalMessages.push("Your portfolio is heavily concentrated in one asset type. Diversification is critical for risk management.");
    }
  
    // Set messages
    setCriticalMessages(newCriticalMessages);
    setWarningMessages(newWarningMessages);
    setSuggestionMessages(newSuggestionMessages);
  
    // Generate chart data
    const data = generateChartData();
    setChartData(data);
  };

  const handleSubmit = () => {
    applyAgeBasedRule();
  };

  return (
    <div>
      <div className={styles.formGroup}>
        <h2>Portfolio Allocation Calculator</h2>
        <Form>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <UserInfoForm
                  age={age}
                  country={country}
                  income={income}
                  onAgeChange={(e) => setAge(e.target.value)}
                  onCountryChange={(e, { value }) => setCountry(value)}
                  onIncomeChange={(e) => setIncome(e.target.value)}
                />
              </Grid.Column>
              <Grid.Column>
                <AssetForm
                  assetOptions={assetOptions}
                  newAsset={newAsset}
                  onAssetChange={handleAssetChange}
                  addAsset={addAsset}
                  assets={inputs.assets}
                  removeAsset={removeAsset}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Button primary onClick={handleSubmit}>
                  Calculate Portfolio Allocation
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>

      <Messages
        criticalMessages={criticalMessages}
        warningMessages={warningMessages}
        suggestionMessages={suggestionMessages}
      />

      {chartData.labels && (
        <Segment>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <PortfolioChart chartData={chartData} />
              </Grid.Column>
              <Grid.Column width={4}>
                <PortfolioSummary
                  totalAssets={formatNumber(calculateTotalAssets())}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </div>
  );
};

export default PortfolioAllocationCalculator;
